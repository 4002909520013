import Image from 'next/legacy/image'
import { imageLoader } from '@/utils/ImageLoaders'
import IconInstagram from '/public/images/icon-instagram-black.svg'
import IconTikTok from '/public/images/icon-tiktok-black.svg'
import IconFacebook from '/public/images/icon-facebook-black.svg'
import IconTwitter from '/public/images/icon-twitter-black.svg'
import IconWebsite from '/public/images/icon-website-black.svg'
import IconPinterest from '/public/images/icon-pinterest-black.svg'
import IconLinkedin from '/public/images/icon-linkedin-black.svg'
import IconYoutube from '/public/images/icon-youtube-black.svg'

export type SocialMediaIconListProps = {
  instagramUrl?: string
  tikTokUrl?: string
  facebookUrl?: string
  twitterUrl?: string
  websiteUrl?: string
  pinterestUrl?: string
  linkedinUrl?: string
  youtubeUrl?: string
  size?: number
}

export function SocialMediaIconList({
  instagramUrl,
  tikTokUrl,
  linkedinUrl,
  facebookUrl,
  twitterUrl,
  pinterestUrl,
  youtubeUrl,
  websiteUrl,
  size = 32,
}: SocialMediaIconListProps): JSX.Element {
  return (
    <div>
      <ul className="flex gap-2">
        {instagramUrl && (
          <li>
            <a href={instagramUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconInstagram}
                alt="Find Fora Advisor on Instagram"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {tikTokUrl && (
          <li>
            <a href={tikTokUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconTikTok}
                alt="Find Fora Advisor on TikTok"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {linkedinUrl && (
          <li>
            <a href={linkedinUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconLinkedin}
                alt="Find Fora Advisor on LinkedIn"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {facebookUrl && (
          <li>
            <a href={facebookUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconFacebook}
                alt="Find Fora Advisor on Facebook"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {twitterUrl && (
          <li>
            <a href={twitterUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconTwitter}
                alt="Find Fora Advisor on Twitter"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {pinterestUrl && (
          <li>
            <a href={pinterestUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconPinterest}
                alt="Find Fora Advisor on Pinterest"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {youtubeUrl && (
          <li>
            <a href={youtubeUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconYoutube}
                alt="Find Fora Advisor on Youtube"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
        {websiteUrl && (
          <li>
            <a href={websiteUrl} target="_blank" rel="noreferrer">
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 32,
                    quality: 32,
                  })
                }
                src={IconWebsite}
                alt="Find Fora Advisor on Website"
                width={size}
                height={size}
              />
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}
