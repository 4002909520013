import React, { useRef, useState } from 'react'
import { AdvisorProps, AdvisorTestimonial } from './Types'
import { getAdvisorDisplayName } from '@/utils/Helpers'
import { Button } from '../ui/Buttons'
import { ReviewCard } from '../testimonial/ReviewCard'
import { EntryTestimonial } from 'types/generated/contentful-types'
import { REVIEWS_LIMIT } from '../pageBlocks/ReviewsData'

interface AdvisorTestimonialsProps {
  testimonials: AdvisorTestimonial[]
  advisor: AdvisorProps
}

export function AdvisorTestimonials({
  testimonials,
  advisor,
}: AdvisorTestimonialsProps) {
  const titleRef = useRef<HTMLDivElement>(null)
  const [numberToShow, setNumberToShow] = useState(REVIEWS_LIMIT)

  const showMore = () => setNumberToShow((prev) => prev + REVIEWS_LIMIT)
  const hasMore = testimonials.length > numberToShow

  const advisorDisplayName = getAdvisorDisplayName(
    advisor.title,
    advisor.firstName
  )

  return (
    <section className="relative mb-14 lg:mb-[76px] lg:last:mb-12">
      <h2 className="fora-text-h3 md:fora-text-h4" ref={titleRef}>
        {advisorDisplayName}&#39;s reviews
      </h2>
      <div className="flex flex-col mt-6 lg:mt-[52px]">
        {testimonials.slice(0, numberToShow).map((testimonial, index) => {
          return (
            <div
              key={index}
              className="pt-6 pb-8 sm:pb-[34px] last:pb-0 sm:last:pb-0 border-t border-darkShell"
            >
              <ReviewCard
                {...(testimonial as EntryTestimonial)}
                parent="AdvisorTestimonials"
              />
            </div>
          )
        })}
      </div>
      {hasMore && (
        <div className="max-w-max mt-8 sm:max-w-[189px] sm:mx-auto lg:mt-[76px]">
          <Button
            text="View More"
            theme="secondary"
            buttonFn={showMore}
            isDisabled={false}
            isFull
          />
        </div>
      )}
    </section>
  )
}
